
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import Menu from '@/components/Menu.vue';
import NoAccess from '@/components/NoAccess.vue';

import { UserMixin } from '../mixins/User';
import { IMenuItem } from '../models/models';
import { RouteName } from '../router/RouteName';

@Component({
  components: {
    Menu,
    NoAccess,
  },
})
export default class PuzzlesPage extends mixins(UserMixin) {
  menuItems: IMenuItem[] = [
    {
      label: 'Puzzels',
      links: [
        {
          routeObject: { name: RouteName.AllPuzzles },
          label: 'Alle puzzels',
        },
        {
          link: '/puzzles/zip-upload',
          label: 'Puzzels uploaden',
        },
        { link: '/puzzles/create/wordfind', label: 'Maken', tag: 'beta' },
      ],
    },
  ];
}
