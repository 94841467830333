
import format from 'date-fns/format';
import { SchedulerDb, SchedulerRecurrence } from 'functions/src/models/db/Scheduler';
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';

import { Weekdays } from '../../../models/Weekdays';
import ExceptionsEditor from './ExceptionsEditor.vue';

export const DEFAULT_FIRST_DATE = format(new Date(), 'yyyy-MM-dd');
export const DEFAULT_LAST_DATE = '2030-01-01';
export const DEFAULT_RECURRENCE = {
  recur: 'daily',
  days: [0, 1, 2, 3, 4, 5, 6],
} as SchedulerRecurrence;
export const DEFAULT_WEEKS_TO_GENERATE_PUZZLES_FOR = 2;

@Component({
  components: { ExceptionsEditor },
})
export default class SchedulerRecurrenceEditor extends Vue {
  @PropSync('schedulerData') syncedschedulerData!: SchedulerDb;
  @PropSync('firstDate') syncedFirstDate!: string | null;
  @PropSync('lastDate') syncedLastDate!: string | null;
  @PropSync('recurrence') syncedRecurrence!: SchedulerRecurrence;
  @PropSync('weeksToGeneratePuzzlesFor') syncedWeeksToGeneratePuzzlesFor!: number | null;

  weekdays = Weekdays;

  get yesterday() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }

  get firstDateAsDate() {
    if (!this.syncedFirstDate) {
      return new Date();
    }
    return new Date(this.syncedFirstDate);
  }

  set firstDateAsDate(value: Date) {
    this.syncedFirstDate = format(value, 'yyyy-MM-dd');
  }

  get lastDateAsDate() {
    if (!this.syncedLastDate) {
      return new Date();
    }
    return new Date(this.syncedLastDate);
  }

  set lastDateAsDate(value: Date) {
    this.syncedLastDate = format(value, 'yyyy-MM-dd');
  }

  @Watch('syncedRecurrence.recur')
  onRecurChange(recur: 'daily' | 'none') {
    switch (recur) {
      case 'daily':
        this.syncedFirstDate = DEFAULT_FIRST_DATE;
        this.syncedLastDate = DEFAULT_LAST_DATE;
        this.syncedRecurrence = DEFAULT_RECURRENCE;
        this.syncedWeeksToGeneratePuzzlesFor = DEFAULT_WEEKS_TO_GENERATE_PUZZLES_FOR;
        return;
      case 'none':
        this.syncedFirstDate = null;
        this.syncedLastDate = null;
        this.syncedRecurrence = { recur: 'none', days: null, exceptions: null };
        this.syncedWeeksToGeneratePuzzlesFor = null;
        return;
    }
  }
}
