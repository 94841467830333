
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Loading from '@/components/Loading.vue';

import { PuzzlePresetDb } from '../../../../../functions/src/models/db/PuzzlePreset';
import { PuzzlePresetContentItem } from '../../../../../functions/src/models/db/PuzzlePresetContent';
import { UserMixin } from '../../../../mixins/User';
import { PresetOverviewView } from '../../../../models/models';
import { RouteName } from '../../../../router/RouteName';
import { functions } from '../../../../store/db';
import NoAccess from '../../../NoAccess.vue';
import { getAmountOfWeeksLeft } from '../preset-utils';
import BatchPuzzleChecker from './BatchPuzzleChecker.vue';
import PuzzlesRejectedList from './PuzzlesRejectedList.vue';
import PuzzlesStockList from './PuzzlesStockList.vue';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
    Loading,
    BatchPuzzleChecker,
    PuzzlesStockList,
    PuzzlesRejectedList,
    NoAccess,
  },
})
export default class PresetOverview extends mixins(UserMixin) {
  @Prop() view!: PresetOverviewView;
  @Prop() id!: string;
  @PuzzlePresetStore.State('puzzlePresetDetail') puzzlePresetDetail!: PuzzlePresetDb;
  @PuzzlePresetStore.State('puzzlePresetContent') puzzlePresetContent!: (PuzzlePresetContentItem & {
    id: string;
  })[];

  loading = true;
  tableView = this.view ?? 'stock';

  get userHasAccess() {
    if (this.loading || !this.puzzlePresetDetail) return true;

    return (
      !this.puzzlePresetDetail.titles ||
      this.currentUserTitles === 'ALL' ||
      this.puzzlePresetDetail.titles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get routeName() {
    return RouteName;
  }

  get amountOfWeeks() {
    return getAmountOfWeeksLeft(this.puzzlePresetDetail);
  }

  get approvedFromContent() {
    return this.puzzlePresetContent.filter(
      (preset) => preset.status === 'initial' && preset.approved === 'approved'
    ).length;
  }

  setTableView(view: PresetOverviewView) {
    this.tableView = view;
  }

  async updateStock() {
    await functions.httpsCallable('calculateAllStockRest')({
      preset: this.puzzlePresetDetail,
    });
  }

  async mounted() {
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetDetail', this.id);
    // await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetContent', {
    //   presetId: this.$route.params.id,
    //   orderByDirection: this.$route.params.renderer === 'koprol' ? 'desc' : 'asc',
    // });
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetDetail');
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetContent');
  }
}
