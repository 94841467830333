
import { Component, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { TitleDb, TitleFrequency } from '../../functions/src/models/db/Titles';
import { UserTitles } from '../../functions/src/models/db/Users';
import { TypedVue } from '../store/typed-vue';

@Component
export default class TitlePicker extends TypedVue {
  @State('titles') allTitles!: (TitleDb & { id: string })[];
  @Prop() titlesToShow!: UserTitles;
  @Prop() value!: string[];
  @Prop() message!: string;
  @Prop() fieldType!: string;
  @Prop() maxtags: number | undefined;
  @Prop() disabled!: boolean;
  @Prop({ default: 'Titels' }) label!: string;

  filteredTitles: TitleDb[] = [];
  hasMounted = false;

  async mounted() {
    await this.$store.dispatch('bindTitles');
  }

  beforeDestroy() {
    this.$store.dispatch('unbindTitles');
  }

  get titles() {
    if (this.titlesToShow === 'ALL') return this.allTitles;
    return this.allTitles.filter((title) => this.titlesToShow.includes(title.id));
  }

  set selectedTitles(ts: TitleDb[]) {
    this.$emit(
      'input',
      (ts || []).map((t) => t.id)
    );
  }

  get selectedTitles() {
    return (this.value || []).map((schedulerTitle) => {
      return (
        this.titles.find((title: TitleDb) => title.id === schedulerTitle) || {
          name: 'Verwijderde titel',
          frequency: TitleFrequency.CUSTOM,
        }
      );
    });
  }

  @Watch('titles')
  onChangeValues() {
    this.getFilteredTitles('');
  }

  getFilteredTitles(text: string) {
    const titles = this.titles;
    this.filteredTitles = titles.filter((title) => {
      const matchesSearch = title.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      const isSelected = this.selectedTitles.some((selectedTitle) => selectedTitle.id === title.id);
      return matchesSearch && !isSelected;
    });
  }
}
