
import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IHoroscopeSudokuPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import RequireTechniqueEditor from './RequireTechniqueEditor.vue';

@Component({
  components: {
    RequireTechniqueEditor,
  },
})
export default class HoroscopeSudokuPresetEditor extends Vue {
  @PropSync('presetData') preset!: IHoroscopeSudokuPreset;
  mounted() {
    if (!this.preset.generatorParams.requireTechniques) {
      this.$set(this.preset.generatorParams, 'requireTechniques', {});
    }
  }
}
