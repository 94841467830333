
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IMenuItem, IMenuSubLink } from '../models/models';

@Component
export default class Menu extends Vue {
  @Prop() menuItems!: IMenuItem[];

  getRouteTo(link: IMenuSubLink) {
    return link.routeObject ? link.routeObject : { path: link.link ? link.link : '' };
  }
}
