
import { format } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IState } from '../../models/models';
import { mediaService } from '../../services';
import { IAsset } from '../../services/MediaService';
import { clone } from '../../utils';
import { showError } from '../../utils/errors';

// todo: support webp
// todo: support thumbnails

@Component
export default class AssetOverview extends Vue {
  @Prop({ default: false }) pickerMode!: boolean;
  pageSize = 50;
  initiated = false;
  loading = false;
  timeout: any = null;
  searchTerm: string | null = null;
  allAssets: IAsset[] = [];
  searchResult: IAsset[] = [];

  public async mounted() {
    await this.init();
  }

  async init() {
    this.loading = false;
    this.initiated = false;
    this.allAssets = await mediaService.getAssets();
    this.initiated = true;
    this.search();
  }

  startUpload() {
    (this.$refs.filesInput as HTMLInputElement).click();
  }

  async uploadAssets(e: Event) {
    this.loading = true;
    const el = e.target as HTMLInputElement;
    if (!el || !el.files || el.files.length === 0) {
      return null;
    }

    const files = el.files;
    if (!files) {
      return;
    }

    const user = (this.$store.state as IState).currentUserEmail;
    if (!user) {
      throw new Error('No user logged in');
    }

    try {
      await mediaService.uploadFiles(files, user);
      await this.init();
    } catch (err) {
      showError(err, 'Fout bij het uploaden');
      await this.init();
    }
  }

  async pickAsset(assetUrl: string) {
    await this.$store.dispatch('assetSelected', assetUrl);
  }

  prettyMime(mimetype: string) {
    if (!mimetype || mimetype.indexOf('/') === -1) {
      return '';
    }

    return mimetype.split('/')[1];
  }

  prettyDate(date: unknown) {
    if (!date) {
      return '';
    }
    if (typeof date === 'object' && 'seconds' in date && typeof date.seconds === 'number') {
      return format(new Date(date.seconds * 1000), 'yyyy-MM-dd HH:mm');
    }
    if (date instanceof Date) {
      return format(date, 'yyyy-MM-dd HH:mm');
    }
    return '';
  }

  search() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      if (!this.allAssets) {
        this.searchResult = [];
        return;
      }

      const searchTerm = this.searchTerm?.toLowerCase().trim();
      if (!searchTerm) {
        this.searchResult = clone(this.allAssets);
        return;
      }

      this.loading = true;
      this.searchResult = this.allAssets.reduce((all, asset) => {
        const searchables = [asset.title, asset.alt, asset.copyright, asset.privateNotes]
          .concat(asset.tags)
          .join('')
          .toLowerCase()
          .trim();
        if (searchables.indexOf(searchTerm) >= 0) {
          all.push(asset);
        }
        return all;
      }, [] as IAsset[]);

      this.loading = false;
    }, 400);
  }
}
