
import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import { PageDb } from 'functions/src/models/db/Page';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { RouteName } from '../../../router/RouteName';
import { functions } from '../../../store/db';
import Loading from '../../Loading.vue';
import ActionsHeader from '../shared/ActionsHeader.vue';

const PuzzlePageStore = namespace('puzzlePageStore');

@Component({
  components: {
    ActionsHeader,
    Loading,
  },
})
export default class SchedulerVersions extends Vue {
  @Prop() schedulerId!: string;
  @Prop() recur!: 'daily' | 'none';
  @PuzzlePageStore.State('puzzlePageVersions') puzzlePageVersions!: PageDb[];

  isGeneratingNewPage = false;
  isLoadingVersions = false;

  async generateNewVersion() {
    this.isGeneratingNewPage = true;
    const result = await functions.httpsCallable('generateNoneRecurringPuzzlePageRest')({
      schedulerId: this.schedulerId,
    });
    this.isGeneratingNewPage = false;

    if (result.data.page) {
      const route = this.getPageLink(result.data.page);
      this.$router.push(route);
    } else {
      this.$buefy.snackbar.open({
        message: 'Er is iets mis gegaan bij het genereren van de pagina.',
        type: 'is-danger',
      });
    }
  }

  async mounted() {
    this.isLoadingVersions = true;
    await this.$store.dispatch('puzzlePageStore/bindPuzzlePageVersions', this.schedulerId);
    this.isLoadingVersions = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePageStore/undbindPuzzlePageVersions');
  }

  getPageLink(pageVersion: PageDb): { name: RouteName; params: { id?: string } } {
    const compareDates = compareAsc(startOfDay(new Date(pageVersion.date)), startOfDay(new Date()));
    const isInAgenda = compareDates === 1 || compareDates === 0; // Date is in future or today.

    return isInAgenda
      ? { name: RouteName.PrintAgendaItem, params: { id: pageVersion.id } }
      : { name: RouteName.PrintArchiveItem, params: { id: pageVersion.id } };
  }

  formatDate(date: string) {
    return format(new Date(date), 'dd-MM-yyyy');
  }
}
