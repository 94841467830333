
import { GenerateWordfind } from '@q42/42puzzles-wordfind-generator';
import { IGeneratedWordfind } from 'functions/src/models/puzzles/IGeneratedPuzzle';
import Component from 'vue-class-component';

import { db } from '../../../../src/store/db';
import { TypedVue } from '../../../../src/store/typed-vue';
import { textToWords } from './wordfindmaker-utils';

interface IWordlistDb {
  name: string;
  words: string[];
  id: string;
}

@Component({})
export default class WordfindMakerComponent extends TypedVue {
  wordLists: IWordlistDb[] = [];
  wordfind: IGeneratedWordfind | null = null;
  selectedWordlist: number | null = null;
  generatorError = '';
  savingWordlist = false;
  deletingWordlist = false;
  data = {
    words: '',
    forcedWords: '',
    solution: '',
    width: '10',
    height: '10',
  };

  async handleWordlistSave() {
    this.selectedWordlist !== null ? await this.updateWordlist() : await this.askForWordlistName();
  }

  async updateWordlist() {
    try {
      this.savingWordlist = true;
      if (typeof this.selectedWordlist === 'number') {
        await db
          .collection('customWordLists')
          .doc(this.wordLists[this.selectedWordlist].id)
          .update({ words: textToWords(this.data.words) });
      }
      this.updateWordsInUI();
      this.savingWordlist = false;
    } catch (error) {
      this.savingWordlist = true;
      // TODO: handle error
      console.log(error);
    }
  }

  askForWordlistName() {
    this.$buefy.dialog.prompt({
      message: `Naam van de woordlijst?`,
      inputAttrs: {
        maxlength: 30,
      },
      trapFocus: true,
      onConfirm: (value) => this.storeWordlist(value),
    });
  }

  async storeWordlist(_name: string) {
    this.savingWordlist = true;
    try {
      await db
        .collection('customWordLists')
        .add({ words: textToWords(this.data.words), name: _name });

      const newWordlist = this.wordLists.find(({ name }) => name === _name);
      if (newWordlist) {
        this.selectedWordlist = this.wordLists.indexOf(newWordlist);
      }
      this.updateWordsInUI();
      this.savingWordlist = false;
    } catch (error) {
      this.savingWordlist = false;
      // TODO: handle error
      console.log(error);
    }
  }

  confirmDeleteWordlist() {
    this.$buefy.dialog.confirm({
      message: 'Weet je het zeker? Met deze actie verwijder je deze woordenlijst.',
      onConfirm: this.deleteWordList,
    });
  }

  async deleteWordList() {
    try {
      this.deletingWordlist = true;
      if (typeof this.selectedWordlist === 'number') {
        await db
          .collection('customWordLists')
          .doc(this.wordLists[this.selectedWordlist].id)
          .delete();
      }
      this.selectedWordlist = null;
      this.updateWordsInUI();
      this.deletingWordlist = false;
    } catch (error) {
      this.deletingWordlist = true;
      // TODO: handle error
      console.log(error);
    }
  }

  updateWordsInUI() {
    if (typeof this.selectedWordlist === 'number')
      this.data.words = this.wordLists[this.selectedWordlist].words.join(', ');
    else this.data.words = '';
  }

  generateWordFind() {
    this.generatorError = '';
    const input = {
      ...this.data,
      words: textToWords(this.data.words),
      forcedWords: textToWords(this.data.forcedWords),
      width: parseInt(this.data.width),
      height: parseInt(this.data.height),
    };

    const wordfind = GenerateWordfind(input);
    if (!wordfind) {
      this.generatorError =
        'Het is niet gelukt een woordzoeker te genereren. Probeer het opnieuw of voeg meer woorden toe of pas de formaten aan.';
    }
    this.wordfind = wordfind as unknown as IGeneratedWordfind;
  }

  async mounted() {
    await db.collection('customWordLists').onSnapshot((snapshot) => {
      this.wordLists = [];
      snapshot.docs.forEach((doc) => {
        this.wordLists.push({ ...doc.data(), id: doc.id } as IWordlistDb);
      });
    });
  }
}
