
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { PuzzlePresetDb } from '../../../functions/src/models/db/PuzzlePreset';
import { ContentTemplate } from '../../../functions/src/models/puzzles/ContentTemplate';
import { IContentId } from '../../../functions/src/models/puzzles/IPuzzles';
import { ContentPresetChoice } from '../../models/ContentPresetChoice';

@Component({
  computed: mapState(['assetUrl']),
})
export default class ContentEditor extends Vue {
  // Props
  @Prop() name!: string; // Needed for keys
  @Prop() isDefaultContentEditor!: boolean; // We won't have to show the 'current' content in template editor
  @Prop() defaultContent!: ContentTemplate | null;
  @Prop() puzzlePresets!: PuzzlePresetDb[] | null;
  @Prop() updateOnMount!: boolean;

  // Updating Props
  @PropSync('content') syncedContent!: ContentTemplate;
  @PropSync('previewContent') syncedPreviewContent!: ContentTemplate | null;
  @PropSync('reloadPreview') syncedReloadPreview!: number;

  // Data
  // only one open at a time, start with all closed
  isOpen = 99;
  assetContentKey: string | null = null;
  assetRendererParam: string | null = null;
  contentPresets: ContentPresetChoice[] = [];

  // Computed
  get contentKeys() {
    if (!this.syncedContent) return [];
    return Object.keys(this.syncedContent).sort();
  }

  getAvailablePuzzles(_id: string) {
    if (this.puzzlePresets) {
      const preset = this.puzzlePresets.find(({ id }) => id === _id);
      const needsApproval = preset?.needsApproval;
      const type = needsApproval ? 'approved' : 'available';

      return preset
        ? `(${preset.stock[type]} ${needsApproval ? 'goedgekeurd' : 'beschikbaar'})`
        : '';
    }
  }

  // Methods
  mounted() {
    if (this.updateOnMount) {
      this.updatePreview();
    }
  }

  getContentPresetName(presetId: string) {
    const contentPreset = this.contentPresets.find((cp) => cp.id === presetId);
    return contentPreset ? contentPreset.name : 'Geen preset geselecteerd';
  }

  selectContent(contentKey: string, id: string) {
    const chosen = this.contentPresets.find((cp) => cp.id === id);
    if (!this.defaultContent) {
      throw new Error('Uhoh, no template content');
    }
    if (!chosen) {
      throw new Error('Uhoh, no chosen');
    }

    // To guarantee chosen image content override contains a url, prefill with default url
    const rendererParams =
      chosen.renderer === 'image'
        ? this.defaultContent[contentKey].rendererParams
        : chosen.rendererParams;

    this.$set(this.syncedContent[contentKey], 'chosenPuzzlePresetId', id);
    this.$set(this.syncedContent[contentKey], 'renderer', chosen.renderer as IContentId);
    this.$set(this.syncedContent[contentKey], 'rendererParams', { ...rendererParams });

    this.updatePreview();
  }

  updatePreview() {
    if (this.syncedPreviewContent) {
      const previewContent = { ...this.syncedPreviewContent };

      Object.keys(this.syncedContent).forEach((key) => {
        if (previewContent && previewContent[key]) {
          const isEmpty = Object.keys(this.syncedContent[key]).length === 0;
          if (isEmpty && this.defaultContent) {
            previewContent[key] = { ...this.defaultContent[key] };
          } else if (!isEmpty) {
            previewContent[key] = { ...this.syncedContent[key] };
          }
        }
      });

      this.syncedPreviewContent = previewContent;
    }
    this.syncedReloadPreview++;
  }

  resetContent(contentKey: string) {
    this.syncedContent[contentKey] = {};
    this.updatePreview();
  }

  async openAssetModal(e: Event, contentKey: string, rendererParam: string) {
    const puzzlePresets = this.syncedContent[contentKey];
    if (!puzzlePresets || puzzlePresets.renderer !== 'image') {
      return;
    }

    // open the modal
    await this.$store.dispatch('openAssetModal');
    this.assetContentKey = contentKey;
    this.assetRendererParam = rendererParam;
  }

  // Watchers
  @Watch('assetUrl')
  handleAssetUrlChange(newUrl: string) {
    if (!this.assetContentKey || !this.assetRendererParam || !newUrl) {
      return;
    }
    const ct = this.syncedContent[this.assetContentKey];
    if (!ct || !ct.rendererParams) {
      console.error('AssetUrl changed but no spot to put it', { ct });
    } else {
      (ct.rendererParams as Record<string, string>)[this.assetRendererParam] = newUrl;
    }
    this.updatePreview();
    this.assetContentKey = null;
    this.assetRendererParam = null;
  }

  @Watch('puzzlePresets', { immediate: true })
  calculateContentPresets(puzzlePresets: PuzzlePresetDb[] | null) {
    if (!puzzlePresets) {
      return;
    }

    this.contentPresets = (puzzlePresets as ContentPresetChoice[])
      .concat([
        {
          id: 'text',
          renderer: 'text',
          name: 'Tekst',
          rendererParams: { text: '' },
        },
        { id: 'image', renderer: 'image', name: 'Afbeelding', rendererParams: { image: '' } },
      ])
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }
}
