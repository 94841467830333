
import { Component, Prop } from 'vue-property-decorator';

import { PuzzlePresetGenerated } from '../../../functions/src/models/puzzles/Content';
import { TypedVue } from '../../../src/store/typed-vue';
import { rejectionReasons as reasons } from '../../models/rejectionReasons';
import { functions } from '../../store/db';
import { getBase64 } from '../../utils';
import { showError } from '../../utils/errors';

@Component({})
export default class RejectionReasonEditorComponent extends TypedVue {
  @Prop() contentItem!: PuzzlePresetGenerated;
  @Prop() contentKey!: string;
  @Prop() pageId!: string;

  isUpdatingPuzzle = false;
  rejectionReason = null;
  customReason = '';
  rejectionScreenshot: File | null = null;

  get buttonIsDisabled() {
    return !this.rejectionReason || (this.rejectionReason === 'anders' && !this.customReason);
  }

  get puzzleIsUpdating() {
    return this.isUpdatingPuzzle;
  }

  get rejectionReasons() {
    return Object.keys(reasons).map((key) => ({
      key,
      reason: (reasons as Record<string, string>)[key],
    }));
  }

  deleteRejectionScreenShot() {
    this.rejectionScreenshot = null;
  }

  updated() {
    if (this.rejectionReason !== 'anders') {
      this.customReason = '';
    }
  }

  async rejectPuzzle() {
    if (!this.contentItem) {
      return console.error('No content');
    }
    if (
      !this.contentItem.chosenPuzzlePresetId ||
      !(this.contentItem.generatorParams && this.contentItem.generatorParams.id)
    ) {
      return console.error('No puzzle preset id or/and generated puzzle id.');
    }
    try {
      this.isUpdatingPuzzle = true;

      const args: Record<string, string> = {
        pageId: this.pageId,
        presetId: this.contentItem.chosenPuzzlePresetId,
        puzzleId: this.contentItem.generatorParams.id,
        rejectionReason:
          this.rejectionReason === 'anders' ? this.customReason : this.rejectionReason!,
      };

      if (this.rejectionScreenshot) {
        args.rejectionScreenshot = (await getBase64(this.rejectionScreenshot)) as string;
      }

      await functions.httpsCallable('assignNewPuzzle')(args);
      this.$buefy.snackbar.open({
        message: 'Puzzel afgekeurd en vervangen',
        type: 'is-success',
      });
    } catch (err: unknown) {
      showError(err);
    } finally {
      this.isUpdatingPuzzle = false;
    }
    this.closeModal();
  }

  closeModal() {
    this.$emit('close');
  }
}
