
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { PuzzlePresetDb } from '../../../../../functions/src/models/db/PuzzlePreset';
import {
  ContentItemApproved,
  ContentItemStatus,
  PuzzlePresetContentItem,
} from '../../../../../functions/src/models/db/PuzzlePresetContent';
import { stockListKeys } from './stockTableKeys';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component
export default class PuzzlesStockList extends Vue {
  @Prop() puzzlePresetDetail!: PuzzlePresetDb & { id: string };
  @Prop() presetId!: string;
  @PuzzlePresetStore.State('puzzlePresetContent') puzzlePresetContent!: (PuzzlePresetContentItem & {
    id: string;
  })[];

  currentPage = 1;
  statusFilter: PuzzlePresetContentItem['status'] = 'initial';

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetContent');
  }

  get computedQuery() {
    return { filter: this.statusFilter, page: this.currentPage };
  }

  @Watch('computedQuery', { immediate: true })
  async onStatusFilterChanged(
    obj: { filter: PuzzlePresetContentItem['status']; page: number },
    oldObj: { filter: PuzzlePresetContentItem['status']; page: number } | undefined
  ) {
    console.log('update', obj, oldObj);
    if (obj?.filter !== oldObj?.filter || this.puzzlePresetContent.length < obj.page * 20) {
      await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetContent', {
        presetId: this.$route.params.id,
        perPage: 20,
        page: this.currentPage,
        status: this.statusFilter,
        orderByDirection: this.isKoprol ? 'desc' : 'asc',
      });
    }
  }

  get isKoprol() {
    return this.puzzlePresetDetail.renderer === 'koprol';
  }

  get isUploaded() {
    return this.puzzlePresetDetail.source === 'upload';
  }

  get keysToShow() {
    return stockListKeys[this.$route.params.renderer];
  }

  getFormattedDate(date: string) {
    return format(new Date(date), 'd MMMM Y', { locale: nl });
  }

  getStatusType(status: ContentItemStatus) {
    switch (status) {
      case 'initial':
        return 'is-light';
      case 'reserved':
        return 'is-warning is-light';
      case 'used':
        return 'is-success is-light';
    }
  }

  getStatusName(status: ContentItemStatus) {
    switch (status) {
      case 'initial':
        return 'Beschikbaar';
      case 'reserved':
        return 'Gereserveerd';
      case 'used':
        return 'Gebruikt';
    }
  }

  getApprovedType(approved: ContentItemApproved) {
    switch (approved) {
      case 'initial':
        return 'is-light';
      case 'approved':
        return 'is-success is-light';
      case 'rejected':
        return 'is-danger is-light';
    }
  }

  getApprovedName(approved: ContentItemApproved) {
    switch (approved) {
      case 'initial':
        return 'Niet';
      case 'approved':
        return 'Goedgekeurd';
      case 'rejected':
        return 'Afgekeurd';
    }
  }

  getFormattedValue(value: any) {
    if (typeof value === 'object') {
      return Object.values(value).join(', ');
    }

    if (typeof value === 'string') {
      return value.replace('@', 'ij');
    }

    return value;
  }
}
