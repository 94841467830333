
import { Component, Vue } from 'vue-property-decorator';

interface IStudioItem {
  active?: boolean;
  icon: string;
  label: string;
  link?: string;
  style?: string;
}

@Component
export default class Menu extends Vue {
  studio: IStudioItem[] = [
    { icon: 'display', label: 'Digital', link: 'https://dashboard.42puzzles.com' },
    {
      active: true,
      icon: 'newspaper',
      label: 'Print',
      link: 'https://dpgpuzzels.nl',
      style: 'regular',
    },
    { icon: 'inbox', label: 'Content', link: 'https://inbox.42puzzles.com' },
    {
      icon: 'file-lines',
      label: 'Words',
      link: 'https://words.42puzzles.com',
      style: 'regular',
    },
    {
      icon: 'comment-dots',
      label: 'Feedback',
      link: `https://dashboard.42puzzles.com/dpg/feedback`,
      style: 'regular',
    },
  ];
}
