
import { Component, Vue } from 'vue-property-decorator';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

@Component({
  components: {
    BreadCrumbs,
  },
})
export default class ShopHome extends Vue {}
