var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.contentKeys),function(contentKey,contentIndex){return _c('b-collapse',{key:contentKey,staticClass:"card",attrs:{"animation":"slide","open":_vm.isOpen === contentIndex},on:{"open":function($event){_vm.isOpen = contentIndex}}},[_c('div',{staticClass:"card-header",attrs:{"slot":"trigger","role":"button"},slot:"trigger"},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(contentKey)+": "),_c('span',{staticClass:"has-text-weight-normal ml-1",class:{ 'is-capitalized': _vm.content[contentKey].renderer }},[_vm._v(" "+_vm._s(_vm.content[contentKey].renderer ? _vm.getDisplayNameByRenderer(_vm.content[contentKey].renderer) : 'Geen preset geselecteerd')+" "),(
              _vm.content[contentKey].generatorParams &&
              _vm.content[contentKey].generatorParams.difficulty
            )?_c('span',_vm._l((_vm.content[contentKey].generatorParams.difficulty),function(i){return _c('b-icon',{key:i,attrs:{"icon":"star","size":"is-small"}})}),1):_vm._e()])]),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":"checkbox-multiple-marked-outline","aria-label":"Check content"}})],1)]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(_vm.isEmptyObject(_vm.content[contentKey]))?_c('b-message',[_vm._v(" Geen preset geselecteerd, selecteer een preset in het template of schema. ")]):(_vm.content[contentKey].renderer === 'image')?_c('div',{staticClass:"changeImage"},[_c('img',{staticClass:"contentImage",attrs:{"src":_vm.content[contentKey].rendererParams.image}}),(_vm.canModify)?_c('b-button',{attrs:{"type":"is-primary is-light","loading":_vm.isUpdatingPuzzle,"outlined":""},on:{"click":function($event){return _vm.openAssetModal($event, contentKey, 'image')}}},[_vm._v("Kies nieuwe afbeelding ")]):_vm._e()],1):(_vm.content[contentKey].renderer === 'text')?_c('div',[_c('b-input',{attrs:{"size":"is-small"},model:{value:(_vm.content[contentKey].rendererParams.text),callback:function ($$v) {_vm.$set(_vm.content[contentKey].rendererParams, "text", $$v)},expression:"content[contentKey].rendererParams.text"}}),_c('br'),_c('b-message',{attrs:{"size":"is-small"}},[_c('b',[_vm._v("@DD")]),_vm._v(" wordt vervangen door de dag, "),_c('b',[_vm._v("@MM")]),_vm._v(" door de maand, "),_c('b',[_vm._v("@WW")]),_vm._v(" door het weeknummer, "),_c('b',[_vm._v("@YYYY")]),_vm._v(" of "),_c('b',[_vm._v("@YY")]),_vm._v(" door het jaartal. "),_c('b',[_vm._v("@SS")]),_vm._v(" = datum van de oplossingen, "),_c('b',[_vm._v("@PP")]),_vm._v(" = pagina nummer. ")]),(_vm.canModify)?_c('b-button',{attrs:{"type":"is-primary is-light","loading":_vm.isUpdatingPuzzle,"outlined":""},on:{"click":function($event){return _vm.saveTextChanges(contentKey)}}},[_vm._v(" Opslaan ")]):_vm._e()],1):_c('div',[(
              _vm.content[contentKey].chosenPuzzlePresetId &&
              _vm.content[contentKey].generatorParams &&
              _vm.content[contentKey].generatorParams.id
            )?_c('p',[_c('strong',[_vm._v("Gebruikte puzzel:")]),_c('router-link',{staticClass:"is-capitalized",attrs:{"to":`/puzzles/${_vm.content[contentKey].renderer}/${_vm.content[contentKey].chosenPuzzlePresetId}/content/${_vm.content[contentKey].generatorParams.id}`}},[_vm._v(" "+_vm._s(_vm.content[contentKey].generatorParams.number ? _vm.content[contentKey].generatorParams.number : _vm.content[contentKey].generatorParams.id)+" ")])],1):_vm._e(),_c('p',[_c('strong',[_vm._v("Gebruikte voorraadbakje:")]),_c('router-link',{staticClass:"is-capitalized",attrs:{"to":`/puzzles/${_vm.content[contentKey].renderer}/${_vm.content[contentKey].chosenPuzzlePresetId}`}},[_vm._v(" "+_vm._s(_vm.getDisplayNameByRenderer(_vm.content[contentKey].renderer))+" ")])],1),(_vm.canModify)?_c('b-message',{attrs:{"type":"is-warning","size":"is-small"}},[_vm._v(" Bij het afkeuren wordt er een nieuwe puzzel gekozen voor deze pagina. De puzzel wordt ook niet meer gebruikt in andere pagina's. ")]):_vm._e(),(_vm.canModify)?_c('b-button',{attrs:{"loading":_vm.isUpdatingPuzzle,"type":"is-primary is-danger"},on:{"click":function($event){return _vm.rejectAndUpdatePuzzle(_vm.content[contentKey], contentKey)}}},[_vm._v(" Afkeuren ")]):_vm._e()],1)],1)])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }