
// eslint-disable-next-line simple-import-sort/imports
import { getLayoutById } from '../../../../functions/src/layouts';
import { SchedulerDb } from '../../../../functions/src/models/db/Scheduler';
import { Component, Prop, PropSync } from 'vue-property-decorator';

import TitlePicker from '../../../components/TitlePicker.vue';
import PagesEditor from './PagesEditor.vue';
import { namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { UserMixin } from '../../../mixins/User';

const SchedulerStore = namespace('schedulerStore');

@Component({
  components: {
    TitlePicker,
    PagesEditor,
  },
})
export default class SchedulerEditorDetails extends mixins(UserMixin) {
  @SchedulerStore.State('schedulers') schedulers!: SchedulerDb & { id: string }[];
  @PropSync('schedulerData') syncedSchedulerData!: SchedulerDb;
  @Prop() areVariantsDisabled!: boolean;
  @Prop() isNewScheduler!: boolean;
  @Prop() hasError!: boolean;

  get selectedLayout() {
    return getLayoutById(this.syncedSchedulerData?.pages[0]?.layoutId);
  }

  // Computed
  get hasName() {
    return !!this.syncedSchedulerData.name;
  }

  get hasWrongAmountOfTitles() {
    return this.areVariantsDisabled && this.syncedSchedulerData.titles.length > 1;
  }

  get titlesMessage() {
    if (this.hasError) {
      return this.hasWrongAmountOfTitles
        ? `Per titel kun je een variant van de content maken. Je hebt gekozen voor meerdere pagina's. Je kunt één titel kiezen.`
        : this.syncedSchedulerData.titles.length <= 0
        ? 'Selecteer minimaal 1 titel.'
        : '';
    }

    return `Per titel kun je een variant van de content maken.`;
  }

  get selectedLayouts() {
    return this.syncedSchedulerData.pages.map((p) => getLayoutById(p.layoutId));
  }

  get showSolutionBlock() {
    if (!this.selectedLayouts.length) {
      return null;
    }
    const requiresSolutions = !!this.selectedLayouts.find(
      (l) => l?.json.page.settings.solutionsFromOtherSchema
    );
    return requiresSolutions;
  }
}
