
import { VueConstructor } from 'vue';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';
import PreviewPuzzle from '@/components/PreviewPuzzle.vue';
import TitlePicker from '@/components/TitlePicker.vue';

import { PuzzlePresetDb } from '../../../../../functions/src/models/db/PuzzlePreset';
import { IPuzzleId, PuzzleIds } from '../../../../../functions/src/models/puzzles/IPuzzles';
import { getDefaultPresetData } from '../../../../../functions/src/models/puzzles/PresetHelper';
import { getDisplayNameByRenderer } from '../../../../../functions/src/utils/puzzleUtils';
import { UserMixin } from '../../../../mixins/User';
import { RouteName } from '../../../../router/RouteName';
import { db, functions } from '../../../../store/db';
import { showError } from '../../../../utils/errors';
import NoAccess from '../../../NoAccess.vue';
import { getPresetEditorComponent } from '../PresetsHelper';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
    PreviewPuzzle,
    TitlePicker,
    NoAccess,
  },
})
export default class PresetEditor extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresetDetail') puzzlePresetDetail!: PuzzlePresetDb;
  @Prop() id!: string;

  activeTab = 0;
  isNewPreset = !this.id;
  renderers: { key: IPuzzleId; label: string }[] = PuzzleIds.map((r) => ({
    key: r,
    label: getDisplayNameByRenderer(r),
  })).sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  renderer: IPuzzleId = this.renderers[0].key;
  presetComponent: VueConstructor<Vue> = getPresetEditorComponent(this.renderer);
  presetData: PuzzlePresetDb = getDefaultPresetData(this.renderer);
  formError = false;
  loading: Record<string, boolean> = {
    generatePuzzlePresetContentItemsRest: false,
    removeUnusedStock: false,
    removeAllStockOfPreset: false,
    deletePuzzlePreset: false,
  };
  isLoadingData = true;

  get userHasAccess() {
    if (this.isLoadingData || this.isNewPreset) return true;

    return (
      !this.puzzlePresetDetail.titles ||
      this.currentUserTitles === 'ALL' ||
      this.puzzlePresetDetail.titles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get breadCrumbs() {
    const baseBreadCrumbs = [
      {
        name: 'Alle puzzels',
        routeObject: { name: RouteName.AllPuzzles },
      },
    ];
    return this.isNewPreset
      ? [...baseBreadCrumbs, { name: 'Nieuwe puzzel' }]
      : [
          ...baseBreadCrumbs,
          {
            name: this.puzzlePresetDetail.name,
            routeObject: {
              name: RouteName.PresetOverview,
              params: {
                renderer: this.puzzlePresetDetail.renderer,
                id: this.puzzlePresetDetail.id,
              },
            },
          },
          { name: 'Aanpassen' },
        ];
  }

  get routeName() {
    return RouteName;
  }

  get puzzleName() {
    if (!this.renderer) {
      return 'Alle';
    }
    const rendererInfo = this.renderers.find((renderer) => renderer.key === this.renderer);
    return rendererInfo ? rendererInfo.label : '';
  }

  async mounted() {
    if (this.isNewPreset) {
      return;
    }
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetDetail', this.id);
    this.isLoadingData = false;
  }

  async deletePuzzlePreset() {
    const { result } = await this.$buefy.dialog.confirm({
      title: 'Weet je het zeker?',
      message:
        'Het puzzelbakje inclusief alle puzzels wordt verwijderd. Dit kan niet ongedaan gemaakt worden.',
      confirmText: 'Verwijder',
      cancelText: 'Behoud',
      type: 'is-danger',
    });
    if (result) {
      this.loading.deletePuzzlePreset = true;
      try {
        await this.$store.dispatch('deletePuzzlePreset', this.id);
        this.loading.deletePuzzlePreset = false;
        this.$router.push({ name: RouteName.AllPuzzles });
      } catch (err) {
        this.loading.deletePuzzlePreset = false;
        showError(err);
      }
    }
  }

  async savePreset() {
    if (!this.presetData.name) {
      this.formError = true;
      return this.$buefy.snackbar.open({
        message: 'Naam is verplicht',
        type: 'is-danger',
      });
    }

    this.formError = false;

    try {
      if (this.isNewPreset) {
        const presetDetail = await db.collection('puzzlepresets').add(this.presetData);
        this.$router.push({
          name: RouteName.PresetOverview,
          params: { renderer: this.presetData.renderer, id: presetDetail.id },
        });
      } else {
        await db.collection('puzzlepresets').doc(this.id).set(this.presetData);
      }
    } catch (err: unknown) {
      showError(err);
    }
    this.$buefy.snackbar.open({
      message: 'Opgeslagen!',
      type: 'is-success',
    });
  }

  beforeDestroy() {
    if (this.isNewPreset) {
      return;
    }

    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetDetail');
  }

  @Watch('renderer')
  onChangeRenderer(renderer: IPuzzleId) {
    if (!this.isNewPreset) {
      return;
    }

    this.presetComponent = getPresetEditorComponent(renderer);
    this.presetData = getDefaultPresetData(renderer);
  }

  @Watch('puzzlePresetDetail', { immediate: true, deep: true })
  onChangePuzzlePresetDetail(puzzlePresetDetail: PuzzlePresetDb) {
    if (!puzzlePresetDetail) {
      return;
    }

    this.presetData = { id: this.id, ...puzzlePresetDetail };
    this.presetComponent = getPresetEditorComponent(puzzlePresetDetail.renderer);
    this.renderer = puzzlePresetDetail.renderer;
  }

  async callFunction(name: string, data?: any) {
    try {
      this.loading[name] = true;
      console.log('call', name, data);
      const response = await functions.httpsCallable(name)(data);
      console.log(`Response van ${name}`, response);
      this.$buefy.snackbar.open({
        message: `Uitgevoerd: ${name}(${data ? JSON.stringify(data) : ''})`,
        type: 'is-success',
      });
    } catch (err) {
      showError(err, 'Uh-oh... check the console');
    }
    this.loading[name] = false;
  }

  async removeAllPuzzlesOfPreset(id: string) {
    const { result } = await this.$buefy.dialog.confirm({
      title: 'Weet je het zeker?',
      message:
        'Je verwijdert alle ongebruikte puzzels uit dit bakje. Dit kan niet ongedaan gemaakt worden!',
      confirmText: 'Verwijder',
      cancelText: 'Behoud',
      type: 'is-danger',
    });
    if (result) {
      this.callFunction('removeAllStockOfPreset', { id });
    }
  }
}
