
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import Menu from '@/components/Menu.vue';
import NoAccess from '@/components/NoAccess.vue';

import { UserMixin } from '../mixins/User';
import { IMenuItem } from '../models/models';

@Component({
  components: {
    Menu,
    NoAccess,
  },
})
export default class AdminPage extends mixins(UserMixin) {
  menuItems: IMenuItem[] = [
    {
      label: 'Gebruikers',
      links: [
        {
          link: '/admin/users',
          label: 'Gebruikers',
        },
      ],
    },
    {
      label: 'Titels',
      links: [
        {
          link: '/admin/titles',
          label: 'Titels',
        },
      ],
    },
    {
      label: 'Geavanceerd',
      links: [
        {
          link: '/admin/debug/debugactions',
          label: 'Handmatige acties',
        },
        {
          link: '/admin/debug/database',
          label: 'Database consistentie',
        },
        {
          link: '/admin/debug/renderers',
          label: 'Puzzel renderer check',
        },
      ],
    },
  ];
}
