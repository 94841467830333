
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import max from 'date-fns/max';
import min from 'date-fns/min';
import subDays from 'date-fns/subDays';
import sortBy from 'lodash/sortBy';
import { Component, Prop } from 'vue-property-decorator';

import { PageDb } from '../../../functions/src/models/db/Page';
import { TypedVue } from '../../store/typed-vue';

@Component
export default class Agenda extends TypedVue {
  @Prop() isArchive!: boolean;
  @Prop() startDate!: Date;
  @Prop() puzzlePages!: PageDb[];

  getPuzzlePagesByDate(date: Date) {
    const puzzlePages: PageDb[] = this.puzzlePages;

    if (!puzzlePages) {
      return [];
    }

    const formattedDate = format(date, 'yyyy-MM-dd');
    return sortBy(
      puzzlePages.filter((puzzlePage) => puzzlePage.date === formattedDate),
      ['schedulerName']
    );
  }

  getFormattedDate(date: Date) {
    return format(date, 'EEEE d MMMM yyyy', { locale: nl });
  }

  get shownDateRange() {
    let endDate = new Date(this.startDate);

    if (this.isArchive) {
      endDate.setDate(this.startDate.getDate() - 14);
      const yesterday = subDays(new Date(), 1);

      if (endDate > yesterday) {
        endDate = yesterday;
      }
    } else {
      endDate.setDate(this.startDate.getDate() + 14);
    }

    const days = eachDayOfInterval({
      start: min([this.startDate, endDate]),
      end: max([this.startDate, endDate]),
    });

    return this.isArchive ? days.reverse() : days;
  }
}
