
import { Component, PropSync, Vue } from 'vue-property-decorator';

import { I0hh1Preset } from '../../../../../functions/src/models/db/PuzzlePreset';
import RequireTechniqueEditor from './RequireTechniqueEditor.vue';

@Component({
  components: {
    RequireTechniqueEditor,
  },
})
export default class Ohh1PresetEditor extends Vue {
  @PropSync('presetData') preset!: I0hh1Preset;

  mounted() {
    if (!this.preset.generatorParams.difficulty) {
      this.$set(this.preset.generatorParams, 'difficulty', 1);
    }
    if (!this.preset.generatorParams.size) {
      this.$set(this.preset.generatorParams, 'size', 8);
    }
  }
}
