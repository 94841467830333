
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

import { ActionLogDb } from '../../../functions/src/models/db/UserActionLogs';
import { getPageActionMessage } from '../../../functions/src/models/db/UserActionLogs';
import { UserMixin } from '../../mixins/User';

@Component
export default class ActionLogEntry extends mixins(UserMixin) {
  @Prop() log!: ActionLogDb;

  get logMessage() {
    return `[${this.log.date}]: ${getPageActionMessage(this.log)}`;
  }
}
