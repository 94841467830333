
import { LayoutDb } from 'functions/src/models/db/Layout';
import { SchedulerDb, SchedulerPageDb } from 'functions/src/models/db/Scheduler';
import { Component, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { layouts as _layouts } from '../../../../functions/src/layouts';
import { SchedulerException } from '../../../../functions/src/models/db/Scheduler';
import { TypedVue } from '../../../store/typed-vue';
import DatePicker from '../../common/DatePicker.vue';

const SchedulerStore = namespace('schedulerStore');

@Component({
  components: {
    DatePicker,
  },
})
export default class ExceptionsEditor extends TypedVue {
  @SchedulerStore.State('schedulers') schedulers!: SchedulerDb & { id: string }[];
  @PropSync('schedulerData') syncedSchedulerData!: SchedulerDb;

  updated() {
    // This adds the page index to an changeLayout exception when there are multiple pages
    if (this.hasMultiplePages && this.syncedSchedulerData.exceptions.length) {
      this.syncedSchedulerData.exceptions.forEach((e) => {
        if (e.type === 'changeTemplate' && e.layoutId) {
          const exceptionLayout = _layouts.find(({ id }) => id === e.layoutId) as LayoutDb;
          const page = this.syncedSchedulerData.pages.find(
            ({ layoutId }) => layoutId === exceptionLayout.exceptionForLayoutId
          ) as SchedulerPageDb;

          e.pageIndex = this.syncedSchedulerData.pages.indexOf(page);
        }
      });
    }
  }

  // Computed;
  get hasExceptionLayouts() {
    return this.exceptionLayouts.length > 0;
  }

  get hasVariants() {
    return this.syncedSchedulerData.titles.length > 1;
  }

  get hasMultiplePages() {
    return this.syncedSchedulerData.pages.length > 1;
  }

  get changeLayoutDisabled() {
    return !this.hasExceptionLayouts;
  }

  get baseLayoutIds() {
    return this.syncedSchedulerData.pages.map((p) =>
      typeof p.layoutId === 'string' ? p.layoutId : undefined
    );
  }

  get exceptionLayouts() {
    return !this.baseLayoutIds.length
      ? []
      : _layouts.filter((layout) => {
          return this.baseLayoutIds.includes(layout.exceptionForLayoutId);
        });
  }

  // Methods
  addException() {
    const emptyException: SchedulerException = {
      date: null,
      type: 'exclude',
    };

    this.syncedSchedulerData.exceptions.push(emptyException);
  }

  deleteException(index: number) {
    this.syncedSchedulerData.exceptions.splice(index, 1);
  }

  handleChange(value: string, index: number) {
    switch (value) {
      case 'exclude':
      case 'include':
        return this.selectExclude(index, value);
      case 'changeTemplate':
        return this.selectChangeTemplate(index);
    }
  }

  selectExclude(index: number, value: 'exclude' | 'include') {
    this.$set(this.syncedSchedulerData.exceptions, index, {
      date: null,
      type: value,
    });
  }

  selectChangeTemplate(index: number) {
    this.$set(this.syncedSchedulerData.exceptions, index, {
      date: null,
      type: 'changeTemplate',
      layoutId: null,
      solutionSchedulerId: null,
      solutionsPageDate: null,
    });
  }
}
