
// import blobStream from 'blob-stream';
import { Component, Vue } from 'vue-property-decorator';

// import { getLayoutById } from '../../functions/src/layouts';
// import { PageDb } from '../../functions/src/models/db/Page';
// import { PuzzlePresetContentItem } from '../../functions/src/models/db/PuzzlePresetContent';
// import { TemplateDb } from '../../functions/src/models/db/Template';
// import { IPage } from '../../functions/src/models/ITemplate';
// import { Content, PuzzlePresetGenerated } from '../../functions/src/models/puzzles/Content';
// import { contentItemIsPuzzle } from '../../functions/src/models/TypeGuards';
// import PageRenderer from '../../functions/src/pagerenderer';
// import { db } from '../store/db';

@Component({})
export default class PreviewPage extends Vue {
  //   // Or have data to preview (templates, schedulers)
  //   @Prop() page: (PageDb & { id: string }) | undefined;
  //   @Prop() pages: (IPage | undefined)[] | undefined;
  //   @Prop() contents: Content[] | undefined;
  //   @Prop() fillContents!: boolean;
  //   @Prop() reload!: number;
  //   previewUrl = '';
  //   loading = true;
  //   async mounted() {
  //     await this.loadPreview();
  //   }
  //   doneLoading() {
  //     setTimeout(() => (this.loading = false), 150);
  //   }
  //   async getPages() {
  //     if (this.pages) {
  //       return this.pages;
  //     }
  //     if (this.page) {
  //       // get pages from db template
  //       const template = await db.collection('templates').doc(this.page.templateId).get();
  //       if (template.exists) {
  //         return (template.data() as TemplateDb).pages.map(
  //           (p) => getLayoutById(p.layoutId)?.json.page
  //         );
  //       }
  //     }
  //     return [];
  //   }
  //   async getContents() {
  //     let contents: Content[] = [];
  //     if (this.contents && this.contents.length > 0) {
  //       contents = [...this.contents];
  //     } else if (this.page) {
  //       // get content from page itself
  //       contents = [...(this.page.content as Content[])];
  //     }
  //     if (this.fillContents) {
  //       // fill contents if not already filled
  //       for (let i = 0; i < contents.length; i++) {
  //         const content = contents[i];
  //         await Promise.all(
  //           Object.keys(content).map(async (k) => {
  //             const c = content[k];
  //             if (contentItemIsPuzzle(c)) {
  //               const snapshot = await db
  //                 .collection('puzzlepresets')
  //                 .doc(c.chosenPuzzlePresetId)
  //                 .collection('content')
  //                 .where('approved', '!=', 'rejected')
  //                 .limit(1)
  //                 .get();
  //               if (snapshot.empty || !snapshot.docs[0]) {
  //                 return;
  //               }
  //               // logInfo('snapshot.docs', snapshot.docs);
  //               const data = snapshot.docs[0].data() as PuzzlePresetContentItem;
  //               (c as PuzzlePresetGenerated).generatorParams = {
  //                 ...data.generated,
  //                 id: snapshot.docs[0].id,
  //                 number: data.number,
  //               };
  //             }
  //           })
  //         );
  //       }
  //     }
  //     return contents;
  //   }
  //   @Watch('reload')
  //   async onReload() {
  //     await this.loadPreview();
  //   }
  //   async loadPreview() {
  //     this.loading = true;
  //     let pages = await this.getPages();
  //     let contents = await this.getContents();
  //     if (pages.length === 0 || contents.length === 0) {
  //       this.previewUrl = '';
  //       return;
  //     }
  //     const stream = blobStream() as any;
  //     const renderer = new PageRenderer(
  //       (opts) => {
  //         //@ts-ignore
  //         // eslint-disable-next-line no-undef
  //         return new PDFDocument(opts);
  //       },
  //       {
  //         date: new Date(),
  //         includeWatermark: true,
  //       },
  //       stream
  //     );
  //     await renderer.render(pages, contents);
  //     stream.on('finish', () => {
  //       this.previewUrl = stream.toBlobURL('application/pdf');
  //     });
  //   }
}
