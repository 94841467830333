
import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IKoprolPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class KoprolPresetEditor extends Vue {
  @PropSync('presetData') preset!: IKoprolPreset;

  addFeed() {
    this.preset.generatorParams?.feeds.push('');
  }

  addSize() {
    this.preset.generatorParams?.sizes.push({ width: 5, height: 5 });
  }
}
