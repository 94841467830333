
import { IState } from 'src/models/models';
import { Component, Vue } from 'vue-property-decorator';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

import { getReadableNameForFrequency } from '../../../functions/src/models/db/Titles';
import { db } from '../../store/db';
import { showError } from '../../utils/errors';
import TitleDetail from './TitleDetail.vue';

@Component({
  components: {
    BreadCrumbs,
    TitleDetail,
  },
})
export default class Titles extends Vue {
  getReadableNameForFrequency = getReadableNameForFrequency;

  get titles() {
    return (this.$store.state as IState).titles;
  }

  async mounted() {
    await this.$store.dispatch('bindTitles');
  }

  async deleteTitle(id: string) {
    const { result } = await this.$buefy.dialog.confirm({
      title: 'Weet je het zeker?',
      message:
        "Bestaande schema's voor deze titel blijven behouden, maar er kunnen geen nieuwe schema's voor gemaakt worden.",
      confirmText: 'Verwijder',
      cancelText: 'Behoud',
      type: 'is-danger',
    });
    if (result) {
      await db.collection('titles').doc(id).delete();
    }
  }

  beforeDestroy() {
    this.$store.dispatch('unbindTitles');
  }

  async newTitle() {
    const { result } = await this.$buefy.dialog.prompt({
      title: 'Wat is de naam van de titel?',
      message: 'Je kunt deze titel later ergens in de hierarchie plaatsen.',
      trapFocus: true,
    });
    if (result) {
      const id = result.toLowerCase().replace(/[^a-z0-9]/gi, '-');
      const current = await db.collection('titles').doc(id).get();
      if (current.exists) {
        this.$buefy.snackbar.open({
          message: `Deze titel bestaat al!`,
          type: 'is-danger',
        });
        return;
      }
      try {
        await db.collection('titles').doc(id).set({ name: result });
        this.$buefy.snackbar.open({
          message: 'Aangemaakt!',
          type: 'is-success',
        });
      } catch (error) {
        return showError(error);
      }
    }
  }
}
