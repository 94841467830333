
import { PuzzlePresetDb } from 'functions/src/models/db/PuzzlePreset';
import { SchedulerDb } from 'functions/src/models/db/Scheduler';
import isEmpty from 'lodash/isEmpty';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PreviewPage from '@/components/PreviewPage.vue';

import { getLayoutById } from '../../../../functions/src/layouts';
import { UserMixin } from '../../../mixins/User';
import { RouteName } from '../../../router/RouteName';
import { clone, intersect } from '../../../utils';
import { showError } from '../../../utils/errors';
import BreadCrumbs from '../../BreadCrumbs.vue';
import Loading from '../../Loading.vue';
import NoAccess from '../../NoAccess.vue';
import ContentEditor from '../ContentEditor.vue';
import ActionsHeader from '../shared/ActionsHeader.vue';
import SchedulerEditorDetails from './SchedulerEditorDetails.vue';
import SchedulerRecurrenceEditor, {
  DEFAULT_FIRST_DATE,
  DEFAULT_LAST_DATE,
  DEFAULT_RECURRENCE,
  DEFAULT_WEEKS_TO_GENERATE_PUZZLES_FOR,
} from './SchedulerRecurrenceEditor.vue';
import SchedulerVariantEditor from './SchedulerVariantEditorNew.vue';
import SchedulerVersions from './SchedulerVersions.vue';

const SchedulerStore = namespace('schedulerStore');
const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
    SchedulerEditorDetails,
    ContentEditor,
    PreviewPage,
    SchedulerVariantEditor,
    SchedulerRecurrenceEditor,
    SchedulerVersions,
    ActionsHeader,
    Loading,
    NoAccess,
  },
})
export default class SchedulerEditorNew extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresets') allPuzzlePresets!: PuzzlePresetDb[];
  @SchedulerStore.State('schedulerDetail') schedulerDetail!: SchedulerDb & { id: string };
  @SchedulerStore.Action('saveScheduler') saveScheduler!: (
    data: SchedulerDb & { id: string }
  ) => Promise<void>;
  @SchedulerStore.Action('saveNewScheduler') saveNewScheduler!: (
    data: SchedulerDb
  ) => Promise<void>;

  @Prop() id!: string;

  isNewScheduler = !this.id;
  activeTab = 0;
  activeContentPageTab = 0;
  activeVariantTab = 0;
  showPreview = false;
  hasError = false;
  loading = true;
  isDeleting = false;

  newScheduler: SchedulerDb = {
    name: '',
    titles: [],
    firstDate: DEFAULT_FIRST_DATE,
    lastDate: DEFAULT_LAST_DATE,
    recurrence: DEFAULT_RECURRENCE,
    content: null,
    pages: [
      {
        layoutId: null,
      },
    ],
    variants: {},
    weeksToGeneratePuzzlesFor: DEFAULT_WEEKS_TO_GENERATE_PUZZLES_FOR,
    // TODO: delete this property later when we deleted the old SchedulerEditor
    solutionSchedulerIds: [],
    solutionSchedulerId: '',
    exceptions: [],
  };

  schedulerData: SchedulerDb = { ...this.newScheduler };

  get userHasAccess() {
    if (this.loading || this.newScheduler) return true;

    return (
      this.currentUserTitles === 'ALL' ||
      this.schedulerDetail.titles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get puzzlePresets() {
    if (this.currentUserTitles === 'ALL') return this.allPuzzlePresets;

    const puzzlePresetsWithoutTitle = this.allPuzzlePresets.filter(
      (puzzlePreset) => !puzzlePreset.titles
    );
    const puzzlePresetsUser = this.allPuzzlePresets.filter((puzzlePreset) => {
      if (!puzzlePreset.titles) return false;

      return puzzlePreset.titles.some((title) => this.currentUserTitles.includes(title));
    });

    return [...puzzlePresetsWithoutTitle, ...puzzlePresetsUser];
  }

  get routeName() {
    return RouteName;
  }

  get currentPage() {
    if (!this.schedulerData.pages || this.schedulerData.pages.length === 0) {
      return null;
    }

    const activePage = this.schedulerData.pages[this.activeContentPageTab];
    if (!activePage || !activePage.layoutId) {
      return null;
    }

    return {
      page: getLayoutById(activePage.layoutId)?.json.page,
      content: activePage.content,
    };
  }

  get previewPage() {
    const isVariantsActive = this.activeTab === 3;
    const isContentActive = this.activeTab === 2;

    if (isVariantsActive) {
      const activeVariantKey = Object.keys(this.schedulerData.variants)[this.activeVariantTab];
      const variantContent = clone(this.schedulerData.pages[0].content) || {};
      Object.keys(variantContent).forEach((contentKey) => {
        if (!isEmpty(this.schedulerData.variants[activeVariantKey].content[contentKey])) {
          variantContent[contentKey] =
            this.schedulerData.variants[activeVariantKey].content[contentKey];
        }
      });
      return {
        page: getLayoutById(this.schedulerData.pages[0].layoutId)?.json.page,
        content: variantContent,
      };
    }

    if (isContentActive) {
      return {
        page: getLayoutById(this.schedulerData.pages[this.activeContentPageTab].layoutId)?.json
          .page,
        content: this.schedulerData.pages[this.activeContentPageTab].content,
      };
    }

    return {
      page: getLayoutById(this.schedulerData.pages[0].layoutId)?.json.page,
      content: this.schedulerData.pages[0].content,
    };
  }

  get schedulerTitles() {
    return this.schedulerData.titles;
  }

  get firstPageLayoutId() {
    return this.schedulerData.pages[0].layoutId;
  }

  get areVariantsDisabled() {
    return this.schedulerData.pages.length > 1;
  }

  get filteredPuzzlePresets() {
    const titles = this.schedulerData.titles ?? [];
    return this.puzzlePresets.filter(
      (pp) => !pp.titles || pp.titles.length === 0 || intersect(pp.titles, titles).length > 0
    );
  }

  async mounted() {
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresets');
    await this.$store.dispatch('schedulerStore/bindSchedulers', this.currentUserTitles);

    if (!this.isNewScheduler) {
      await this.$store.dispatch('schedulerStore/bindSchedulerDetail', this.id);
    }
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresets');
    this.$store.dispatch('schedulerStore/unbindSchedulers');

    if (!this.isNewScheduler) {
      this.$store.dispatch('schedulerStore/unbindSchedulerDetail');
    }
  }

  setActiveVariantTab(activeVariantTab: number) {
    this.activeVariantTab = activeVariantTab;
  }

  updateVariants(selectedTitles: string[]) {
    // Delete titles that are not selected
    Object.keys(this.schedulerData.variants).forEach((variantKey) => {
      const isSelected = selectedTitles.some((title) => title === variantKey);
      if (!isSelected) {
        delete this.schedulerData.variants[variantKey];
      }
    });

    // Add new titles
    selectedTitles.forEach((selectedTitle) => {
      const exists = Object.keys(this.schedulerData.variants).some(
        (variantKey) => variantKey === selectedTitle
      );
      if (!exists) {
        this.$set(this.schedulerData.variants, selectedTitle, {});
        this.$set(this.schedulerData.variants[selectedTitle], 'content', {});
        this.setVariantContentKeys(selectedTitle);
      }
    });
  }

  setVariantContentKeys(variantKey: string) {
    if (!this.schedulerData.pages[0].content) return;
    const pageContentKeys = Object.keys(this.schedulerData.pages[0].content);
    const variantContentKeys = Object.keys(this.schedulerData.variants[variantKey].content);

    variantContentKeys.forEach((variantContentKey) => {
      const isValidContentKey = pageContentKeys.some(
        (pageContentKey) => pageContentKey === variantContentKey
      );

      if (!isValidContentKey) {
        delete this.schedulerData.variants[variantKey].content[variantContentKey];
      }
    });

    pageContentKeys.forEach((pageContentKey) => {
      const exists = variantContentKeys.some(
        (variantContentKey) => variantContentKey === pageContentKey
      );

      if (!exists) {
        this.$set(this.schedulerData.variants[variantKey].content, pageContentKey, {});
      }
    });
  }

  async saveSchedulerData() {
    const hasName = !!this.schedulerData.name;
    const hasWrongAmountOfTitles = this.areVariantsDisabled && this.schedulerData.titles.length > 1;
    const hasLayoutsFilled = this.schedulerData.pages.every((page) => !!page.layoutId);
    const hasTitles = this.schedulerData.titles.length > 0;

    const isValid = hasName && !hasWrongAmountOfTitles && hasLayoutsFilled && hasTitles;

    if (!isValid) {
      this.hasError = true;
      return this.$buefy.snackbar.open({
        message: 'Er missen of er zijn nog wat onjuiste gegevens.',
        type: 'is-danger',
      });
    }

    this.hasError = false;

    const confirmed = this.isNewScheduler
      ? true
      : (
          await this.$buefy.dialog.confirm({
            title: 'Weet je het zeker?',
            message: `Als je de scheduler aanpast worden alle pagina's die nog niet gebruikt zijn verwijderd en opnieuw aangemaakt adhv de aanpassingen.`,
            confirmText: 'Opslaan',
            cancelText: 'Terug',
            type: 'is-success',
          })
        ).result;

    if (!confirmed) {
      return;
    }

    const schedulerData = this.isNewScheduler
      ? { ...this.schedulerData }
      : { ...this.schedulerData, id: this.id };

    // remove variants for titles if variants are not allowed
    if (this.areVariantsDisabled || this.schedulerData.titles.length <= 1) {
      schedulerData.variants = {};
    }

    try {
      if (this.id) {
        await this.saveScheduler({ ...schedulerData, id: this.id });
      } else {
        await this.saveNewScheduler(schedulerData);
      }
    } catch (err: unknown) {
      return showError(err);
    }

    if (this.isNewScheduler) {
      await this.$router.push({ name: RouteName.PrintPuzzlePageOverview });
    }
    this.$buefy.snackbar.open({
      message: 'Opgeslagen!',
      type: 'is-success',
    });
  }

  async deleteScheduler() {
    this.isDeleting = true;

    const { result } = await this.$buefy.dialog.confirm({
      title: 'Weet je het zeker?',
      message:
        "Het schema en zijn ongebruikte puzzelpagina's worden verwijderd. Het archief blijft behouden.",
      confirmText: 'Verwijder',
      cancelText: 'Behoud',
      type: 'is-danger',
    });
    if (result) {
      await this.$store.dispatch('schedulerStore/deleteScheduler', this.id);
      this.$router.push({ name: RouteName.PrintPuzzlePageOverview });
    }
    this.isDeleting = false;
  }

  @Watch('schedulerDetail', { immediate: true, deep: true })
  onChangePuzzlePresetDetail(schedulerDetail: SchedulerDb) {
    if (!schedulerDetail) {
      return;
    }

    this.schedulerData = { weeksToGeneratePuzzlesFor: 2, ...clone(schedulerDetail) };
  }

  @Watch('schedulerTitles')
  onTitlesChange(newVal: string[]) {
    this.updateVariants(newVal);
  }

  @Watch('firstPageLayoutId')
  onFirstPageLayoutIdChange() {
    Object.keys(this.schedulerData.variants).forEach((variantKey) => {
      this.setVariantContentKeys(variantKey);
    });
  }
}
