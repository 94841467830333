
import firebase from 'firebase/compat';
import { Component } from 'vue-property-decorator';

import { auth } from '../store/db';
import { TypedVue } from '../store/typed-vue';

@Component
export default class Login extends TypedVue {
  async login() {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      await auth.signInWithPopup(provider);
    } catch (err) {
      console.error('Error during signInWithPopup', err);
      this.$buefy.dialog.alert({
        title: 'Error',
        message: 'Helaas lukt het niet om je in te loggen.',
        type: 'is-danger',
        hasIcon: true,
        icon: 'alert-circle-outline',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    }
  }
}
