
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { OrderDb } from '../../../functions/src/models/db/Order';
import { IUser } from '../../models/models';
import { RouteName } from '../../router/RouteName';
import { db } from '../../store/db';
import { TypedVue } from '../../store/typed-vue';
import { showError } from '../../utils/errors';

const UserStore = namespace('userStore');

@Component
export default class Users extends TypedVue {
  @UserStore.State('users') users!: IUser[];

  currentPage = 1;
  orders: OrderDb[] = [];

  get routeName() {
    return RouteName;
  }

  get mappedUsers() {
    return this.users.map((user) => ({
      ...user,
      id: user.id,
    }));
  }

  async mounted() {
    await this.$store.dispatch('userStore/bindUsers');
  }

  beforeDestroy() {
    this.$store.dispatch('userStore/unbindUsers');
  }

  async createUser() {
    const { result } = await this.$buefy.dialog.prompt({
      title: 'Wat is het e-mailadres?',
      message: 'Hierna kun je specifieke rechten toekennen.',
      trapFocus: true,
      inputAttrs: {
        type: 'email',
      },
    });
    if (result) {
      try {
        await db
          .collection('users')
          .doc(result.toLowerCase())
          .set(
            {
              titles: 'ALL',
              allowShopRead: true,
              allowDownloadLicensedPuzzles: true,
            } as IUser,
            { merge: true }
          );
        this.$buefy.snackbar.open({
          message: 'Aangemaakt!',
          type: 'is-success',
        });
        this.$router.push('/admin/users/' + result.toLowerCase());
      } catch (error) {
        return showError(error);
      }
    }
  }
}
