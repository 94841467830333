
import { Component, Prop, Vue } from 'vue-property-decorator';

import { PuzzleIds } from '../../../../functions/src/models/puzzles/IPuzzles';
import { getDisplayNameByRenderer } from '../../../../functions/src/utils/puzzleUtils';
import { RouteName } from '../../../router/RouteName';
import { PuzzleItem } from './PuzzlesOverview.vue';

@Component
export default class AllPuzzles extends Vue {
  @Prop() puzzles!: PuzzleItem[];

  currentPage = 1;
  puzzleTypeFilter: { id: string; name: string } | null = null;

  get routeName() {
    return RouteName;
  }

  get puzzleTypes() {
    return PuzzleIds.map((puzzleId) => {
      return { id: puzzleId, name: getDisplayNameByRenderer(puzzleId) };
    });
  }

  get filteredPuzzles() {
    if (this.puzzleTypeFilter !== null) {
      return this.puzzles.filter((presetItem) => presetItem.renderer === this.puzzleTypeFilter?.id);
    }

    return this.puzzles;
  }
}
