
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

import { PuzzlePresetDb } from '../../../functions/src/models/db/PuzzlePreset';
import { UploadedPuzzleStats } from '../../../functions/src/models/puzzles/UploadPuzzleStats';
import { UserMixin } from '../../mixins/User';
import { RouteName } from '../../router/RouteName';
import { functions } from '../../store/db';
import { showError } from '../../utils/errors';
import NoAccess from '../NoAccess.vue';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
    NoAccess,
  },
})
export default class PuzzlesUploadDetail extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresetDetail') puzzlePresetDetail!: PuzzlePresetDb;
  @Prop() id!: string;

  dropFiles: File[] = [];
  response: { puzzles: UploadedPuzzleStats[] } | undefined = { puzzles: [] };
  loading = true;

  get userHasAccess() {
    if (this.loading) return true;

    return (
      !this.puzzlePresetDetail.titles ||
      this.currentUserTitles === 'ALL' ||
      this.puzzlePresetDetail.titles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get puzzles() {
    if (this.response && Array.isArray((this.response as any).puzzles)) {
      return (this.response as any).puzzles;
    } else {
      return [{ valid: false, message: 'Unknown response: ' + this.response }];
    }
  }

  get breadCrumbs() {
    if (!this.puzzlePresetDetail) return [];
    return [
      {
        name: 'Alle puzzels',
        routeObject: { name: RouteName.AllPuzzles },
      },
      {
        name: this.puzzlePresetDetail.name,
        routeObject: {
          name: RouteName.PresetOverview,
          params: { renderer: this.puzzlePresetDetail.renderer, id: this.puzzlePresetDetail.id },
        },
      },
      {
        name: 'Upload',
      },
    ];
  }

  @Watch('dropFiles', { deep: true })
  async onUpload(files: File[]) {
    this.response = { puzzles: [] };
    if (files.length === 0) {
      return;
    }

    const contents = await files.reduce(async (prev, file) => {
      const prevArray = await prev;

      try {
        const puzzles = await this.readContents(file);
        return prevArray.concat([{ filename: file.name, puzzles }]);
      } catch (e) {
        console.error('Error reading ' + file.name, e);
        return prevArray.concat([
          { filename: file.name, error: 'Fout bij inlezen. Is dit wel een JSON bestand?' },
        ]);
      }
    }, Promise.resolve([] as unknown[]));

    try {
      this.response = (
        await functions.httpsCallable('uploadPuzzles')({
          puzzlePresetId: this.id,
          files: contents,
        })
      ).data;
    } catch (err) {
      showError(err);
    }
  }

  async mounted() {
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetDetail', this.id);

    this.loading = false;
  }
  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetDetail');
  }

  // eslint-disable-next-line require-await
  async readContents(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      // event fired when file reading finished
      reader.addEventListener('load', function (e: any) {
        // contents of the file, remove BOM
        let text = e.target.result.replace(/^\uFEFF/, '');
        resolve(text);
      });

      // event fired when file reading failed
      reader.addEventListener('error', function () {
        reject(new Error('Failed to read file'));
      });

      // read file as text file
      reader.readAsText(file);
    });
  }
}
