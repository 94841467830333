
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

import {
  getReadableNameForFrequency,
  TitleDb,
  TitleFrequency,
} from '../../../functions/src/models/db/Titles';
import { db } from '../../store/db';
import { showError } from '../../utils/errors';

@Component({
  computed: mapState(['titleDetail']),
  components: {
    BreadCrumbs,
  },
})
export default class TitleDetail extends Vue {
  @Prop() title!: TitleDb;
  @Prop() titles!: TitleDb[];

  get frequencyTypes() {
    return Object.values(TitleFrequency).map((frequency) => {
      return {
        frequency,
        label: getReadableNameForFrequency(frequency),
      };
    });
  }

  @Watch('title', { deep: true })
  async onChangeTitleDetail(title: TitleDb) {
    if (!title || !title.id) {
      return;
    }

    try {
      await db.collection('titles').doc(title.id).update(title);
    } catch (err: unknown) {
      return showError(err);
    }
  }
}
