
import format from 'date-fns/format';
import { filesize } from 'filesize';
import { Component, Vue } from 'vue-property-decorator';

import { mediaService } from '../../services';
import { IAsset } from '../../services/MediaService';
import { clone } from '../../utils';
import { showError } from '../../utils/errors';

@Component
export default class ImageDetails extends Vue {
  initiated = false;
  activeTab = 0;
  asset: IAsset | null = null;
  dirtyAsset: IAsset | null = null;

  public async mounted() {
    this.asset = (await mediaService.getAssetById(this.$route.params.id)) || null;
    this.dirtyAsset = clone(this.asset);
    this.initiated = true;
  }

  get prettyCreatedOn() {
    if (!this.asset) {
      return null;
    }

    const date = new Date(this.asset.createdOn);
    return format(date, 'yyyy-MM-dd');
  }

  get prettyFilesize() {
    if (!this.asset || !this.asset.filesize) {
      return null;
    }

    return filesize(this.asset.filesize);
  }

  get showBack() {
    return window.history.length > 1;
  }

  async goBack() {
    this.showBack ? await this.$router.go(-1) : await this.$router.push('/media/assets');
  }

  async deleteItem() {
    if (!this.asset) {
      return;
    }
    // get confirmation from the user
    const { result } = await this.$buefy.dialog.confirm({
      title: 'Weet je het zeker?',
      message:
        "Het bestand blijft bestaan voor huidige puzzelpagina's, maar je kunt hem hier niet meer terug vinden.",
      confirmText: 'Verwijder',
      cancelText: 'Behoud',
      type: 'is-danger',
    });
    if (result) {
      try {
        await mediaService.deleteAsset(this.asset);
        this.$buefy.snackbar.open({
          message: (this.asset.title || 'Afbeelding') + ' verwijderd',
          type: 'is-success',
        });
        await this.$router.push('/media/assets');
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          type: 'is-danger',
        });
      }
    }
  }

  async saveAsset() {
    if (!this.asset || !this.dirtyAsset) {
      return;
    }

    if (
      this.asset.title === this.dirtyAsset.title &&
      this.asset.alt === this.dirtyAsset.alt &&
      this.asset.copyright === this.dirtyAsset.copyright &&
      this.asset.privateNotes === this.dirtyAsset.privateNotes &&
      this.asset.tags === this.dirtyAsset.tags
    ) {
      return;
    }

    try {
      await mediaService.updateAsset(this.dirtyAsset);
      this.success();
      this.asset = clone(this.dirtyAsset);
    } catch (err) {
      showError(err);
    }
  }

  success() {
    this.$buefy.snackbar.open({
      message: 'Opgeslagen!',
      type: 'is-success',
    });
  }
}
