
import { IState } from 'src/models/models';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { PageDb } from '../../../functions/src/models/db/Page';
import { Content, PuzzlePresetGenerated } from '../../../functions/src/models/puzzles/Content';
import { ContentTemplate } from '../../../functions/src/models/puzzles/ContentTemplate';
import { IPuzzleId } from '../../../functions/src/models/puzzles/IPuzzles';
import { getDisplayNameByRenderer } from '../../../functions/src/utils/puzzleUtils';
import { functions } from '../../store/db';
import { showError } from '../../utils/errors';
import RejectionReasonEditor from './RejectionReasonEditor.vue';

@Component({
  computed: mapState(['assetUrl']),
})
export default class PageContentChecker extends Vue {
  @Prop() isHistory!: boolean;
  @Prop() isUsed!: boolean;
  @Prop() content!: Content | ContentTemplate;
  @Prop({ default: 0 }) pageNr!: number;
  @Prop() variantId?: string | undefined;

  isUpdatingPuzzle = false;
  assetContentKey: string | null = null;

  // only one open at a time, start with all closed
  isOpen = 99;

  get canModify() {
    // dat een pagina "used" is, kan niet meer gerevert worden
    // omdat dat een te desastreuse actie is. Vandaar dat je kleine aanpassingen
    // zoals puzzels afkeuren en afbeeldingen wijzigen, nog wel mag doen.
    // return !this.isHistory && !this.isUsed;
    return !this.isHistory;
  }

  get pp() {
    return (this.$store.state as IState).puzzlePageDetail as PageDb;
  }

  get contentKeys() {
    if (!this.content) return [];

    return Object.keys(this.content).sort();
  }

  getDisplayNameByRenderer(r: IPuzzleId | 'image' | 'text') {
    return getDisplayNameByRenderer(r);
  }

  isEmptyObject(item: { [key: string]: any }) {
    return Object.keys(item).length === 0;
  }

  async rejectAndUpdatePuzzle(contentItem: PuzzlePresetGenerated, contentKey: string) {
    this.$buefy.modal.open({
      component: RejectionReasonEditor,
      hasModalCard: true,
      props: {
        contentItem,
        contentKey,
        pageId: this.pp.id,
      },
    });
  }

  async openAssetModal(e: Event, contentKey: string, rendererParam: string) {
    const puzzlePresets = this.content[contentKey];
    if (!puzzlePresets || puzzlePresets.renderer !== 'image') {
      return;
    }

    // open the modal
    await this.$store.dispatch('openAssetModal');
    this.assetContentKey = contentKey;
  }

  // Watchers
  @Watch('assetUrl')
  async handleAssetUrlChange(imageUrl: string) {
    if (!this.assetContentKey || !imageUrl) {
      return;
    }

    try {
      this.isUpdatingPuzzle = true;
      await functions.httpsCallable('replacePuzzlePageImage')({
        pageId: this.pp.id,
        pageNr: this.pageNr,
        variantId: this.variantId,
        contentKey: this.assetContentKey,
        imageUrl,
      });
      this.$buefy.snackbar.open({
        message: 'De afbeelding is gewijzigd',
        type: 'is-success',
      });
    } catch (err: unknown) {
      showError(err);
    } finally {
      setTimeout(() => {
        // Give firestore some time to return the new data
        this.isUpdatingPuzzle = false;
      }, 1000);
    }
  }

  async saveTextChanges(contentKey: string) {
    if (!contentKey) {
      return;
    }

    try {
      this.isUpdatingPuzzle = true;
      const params = this.content[contentKey].rendererParams;
      if ('text' in params) {
        const newText = params.text;
        await functions.httpsCallable('replacePuzzlePageText')({
          pageId: this.pp.id,
          pageNr: this.pageNr,
          variantId: this.variantId,
          contentKey: contentKey,
          newText,
        });
        this.$buefy.snackbar.open({
          message: 'De tekst is gewijzigd',
          type: 'is-success',
        });
      }
    } catch (err: unknown) {
      showError(err);
    } finally {
      setTimeout(() => {
        // Give firestore some time to return the new data
        this.isUpdatingPuzzle = false;
      }, 1000);
    }
  }
}
