
import { Component, PropSync } from 'vue-property-decorator';

import { IDoorloperCrosswordPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class DoorloperCrosswordPresetEditor extends TypedVue {
  @PropSync('presetData') preset!: IDoorloperCrosswordPreset;
}
