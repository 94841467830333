
import { Component, Prop } from 'vue-property-decorator';

import { TypedVue } from '../store/typed-vue';

@Component
export default class Loading extends TypedVue {
  @Prop() value!: boolean;
  @Prop() label?: string;

  get loading() {
    return this.value;
  }
}
