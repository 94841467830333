
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import { UserMixin } from '../mixins/User';
import { RouteName } from '../router/RouteName';
import { auth } from '../store/db';

@Component
export default class NavBar extends mixins(UserMixin) {
  get routeName() {
    return RouteName;
  }

  async logout(): Promise<void> {
    await auth.signOut();
  }
}
