var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section"},[_vm._m(0),_c('section',{staticClass:"section"},[_c('div',{staticClass:"columns is-variable is-8"},[(_vm.allowShopRead)?_c('div',{staticClass:"column"},[_c('div',{staticClass:"card is-clickable",on:{"click":function($event){return _vm.$router.push({ path: '/shop' })}}},[_c('div',{staticClass:"card-image"},[_c('b-image',{attrs:{"src":require('../assets/home-shop.jpg'),"alt":"Puzzel Shop"}})],1),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('b-image',{staticClass:"is-48x48",attrs:{"src":require('../assets/logo.png'),"alt":"DPG Logo"}})],1),_vm._m(1)]),_vm._m(2)])])]):_vm._e(),(_vm.allowPrintRead)?_c('div',{staticClass:"column"},[_c('div',{staticClass:"card is-clickable",on:{"click":function($event){return _vm.$router.push({ path: '/print' })}}},[_c('div',{staticClass:"card-image"},[_c('b-image',{attrs:{"src":require('../assets/home-print.jpg'),"alt":"Puzzel Printstraat"}})],1),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('b-image',{staticClass:"is-48x48",attrs:{"src":require('../assets/logo.png'),"alt":"DPG Logo"}})],1),_vm._m(3)]),_vm._m(4)])])]):_vm._e(),(_vm.allowUpload)?_c('div',{staticClass:"column"},[_c('div',{staticClass:"card is-clickable",on:{"click":_vm.goToInbox}},[_c('div',{staticClass:"card-image"},[_c('b-image',{attrs:{"src":require('../assets/home-print.jpg'),"alt":"Puzzel Studio"}})],1),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('b-image',{staticClass:"is-48x48",attrs:{"src":require('../assets/logo.png'),"alt":"DPG Logo"}})],1),_vm._m(5)]),_vm._m(6)])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"hero is-primary"},[_c('div',{staticClass:"hero-body"},[_c('p',{staticClass:"title"},[_vm._v("Welkom bij DPG Puzzels")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4"},[_vm._v("Puzzel Shop")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('p',[_vm._v("Hier kun je losse puzzels downloaden. Zo veel je wilt!")]),_c('p',[_vm._v("Toegankelijk voor iedereen binnen DPG Media.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4"},[_vm._v("Puzzel Printstraat")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('p',[_vm._v(" Volledige pagina's gevuld met puzzels, voor direct gebruik in een krant of magazine. ")]),_c('p',[_vm._v("Heeft uitleg nodig. Voor toegang, neem contact op.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4"},[_vm._v("Puzzel Studio")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('p',[_vm._v("Voor het aanleveren en maken van puzzels kun je terecht bij de Puzzel Studio.")]),_c('p',[_vm._v("Hiervoor moet je mogelijk opnieuw inloggen.")])])
}]

export { render, staticRenderFns }