var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.puzzlePresetWithActionUpload.length)?[_c('h2',{staticClass:"title is-4 mb-0"},[_vm._v("Puzzels aanleveren")]),_c('p',[_vm._v(" Onderstaande puzzels hebben niet genoeg voorraad, upload nieuwe puzzels om de voorraad aan te vullen. ")]),_c('b-table',{staticClass:"mt-3",attrs:{"data":_vm.puzzlePresetWithActionUpload}},[_c('b-table-column',{attrs:{"field":"name","label":"Naam"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}],null,false,2982927002)}),_c('b-table-column',{attrs:{"field":"stock.available","label":"Beschikbaar"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.stock.available)+" ")]}}],null,false,3132953130)}),_c('b-table-column',{attrs:{"field":"usage.expectedWeeklyUse","label":"Verwacht gebruik per week"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.usage.expectedWeeklyUse)+" ")]}}],null,false,326461730)}),_c('b-table-column',{attrs:{"field":"amountOfWeeksLeft","label":"Voorraad in weken"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
            'tag',
            { 'is-danger': props.row.amountOfWeeksLeft < 1 },
            { 'is-warning': props.row.amountOfWeeksLeft >= 1 },
          ]},[_vm._v(_vm._s(props.row.amountOfWeeksLeft))])]}}],null,false,679147295)}),_c('b-table-column',{attrs:{"field":"actions","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"is-flex"},[_c('b-button',{staticClass:"ml-3",attrs:{"type":"is-primary","icon-left":"file-multiple-outline","tag":"router-link","outlined":"","to":{
              name: _vm.routeName.PresetOverview,
              params: { renderer: props.row.renderer, id: props.row.id },
            }}},[_vm._v(" Bekijk ")])],1)]}}],null,false,898982098)})],1)]:_vm._e(),(_vm.puzzlePresetWithActionApprove.length)?[_c('h2',{staticClass:"title is-4 mb-0 mt-6"},[_vm._v("Puzzels controleren")]),_c('p',[_vm._v(" Onderstaande puzzels hebben niet genoeg goedgekeurde voorraad, keur puzzels goed om te zorgen dat er alleen goedgekeurde puzzels in de puzzel pagina's worden gebruikt. ")]),_c('b-table',{staticClass:"mt-3",attrs:{"data":_vm.puzzlePresetWithActionApprove}},[_c('b-table-column',{attrs:{"field":"name","label":"Naam"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}],null,false,2982927002)}),_c('b-table-column',{attrs:{"field":"stock.available","label":"Beschikbaar"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.stock.available)+" ")]}}],null,false,3132953130)}),_c('b-table-column',{attrs:{"field":"stock.approved","label":"Aantal goedgekeurd"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-light is-danger"},[_vm._v(" "+_vm._s(props.row.stock.approved)+" ")])]}}],null,false,3708431858)}),_c('b-table-column',{attrs:{"field":"usage.expectedWeeklyUse","label":"Verwacht gebruik per week"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.usage.expectedWeeklyUse)+" ")]}}],null,false,326461730)}),_c('b-table-column',{attrs:{"field":"amountOfWeeksLeft","label":"Goedgekeurde voorraad in weken"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
            'tag',
            { 'is-danger': props.row.amountOfWeeksLeft < 1 },
            { 'is-warning': props.row.amountOfWeeksLeft >= 1 },
          ]},[_vm._v(_vm._s(props.row.amountOfWeeksLeft))])]}}],null,false,679147295)}),_c('b-table-column',{attrs:{"field":"actions","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"is-flex"},[_c('b-button',{attrs:{"type":"is-primary is-light","icon-left":"checkbox-multiple-marked-outline","tag":"router-link","outlined":"","to":{
              name: _vm.routeName.PresetOverview,
              params: { renderer: props.row.renderer, id: props.row.id },
              query: { view: 'approval' },
            }}},[_vm._v(" Controleren ")]),_c('b-button',{staticClass:"ml-3",attrs:{"type":"is-primary","icon-left":"file-multiple-outline","tag":"router-link","outlined":"","to":{
              name: _vm.routeName.PresetOverview,
              params: { renderer: props.row.renderer, id: props.row.id },
            }}},[_vm._v(" Bekijk ")])],1)]}}],null,false,1484960226)})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }