
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { PuzzlePresetDb } from '../../functions/src/models/db/PuzzlePreset';
import { PuzzlePresetContentItem } from '../../functions/src/models/db/PuzzlePresetContent';
import { auth, storage } from '../store/db';
import { db, env, functionsUrl } from '../store/firestore-config';

@Component({})
export default class PreviewPuzzle extends Vue {
  // Or have data to preview (templates, schedulers)
  @Prop() puzzlePreset!: PuzzlePresetDb & { id: string };
  @Prop() puzzle: (PuzzlePresetContentItem & { id: string }) | undefined;

  // previewUrl = '';
  token = '';
  loading = true;
  random = Math.random();
  cacheUrl: string | null = null;

  get previewUrl() {
    return `${functionsUrl}previewPuzzle#toolbar=1&navpanes=0&pagemode=none&statusbar=0`;
  }

  async mounted() {
    if (auth.currentUser) {
      this.token = await auth.currentUser.getIdToken();
    }
    await this.loadPreview();
  }

  doneLoading() {
    // clientside tonen van de pdf duurt ongeveer 500ms op m'n macbook
    setTimeout(() => (this.loading = false), 500);
  }

  @Watch('puzzlePreset')
  @Watch('puzzle')
  async onContentsUpdate() {
    await this.loadPreview();
  }

  async loadPreview() {
    if (env !== 'LOCAL' && db !== 'EMULATOR' && this.puzzle?.pdfCache) {
      const ref = storage.ref(this.puzzle?.pdfCache);
      this.cacheUrl =
        (await ref.getDownloadURL()) + '#toolbar=1&navpanes=0&pagemode=none&statusbar=0';
      return;
    }

    if (!this.puzzlePreset || !this.token) {
      console.log('no preview rendered', this.puzzlePreset, this.token);
      return;
    }

    this.loading = true;

    Vue.nextTick(() => {
      if (!this.cacheUrl && this.$refs.previewIframe) {
        (this.$refs.previewIframe as HTMLFormElement).submit();
      }
    });
  }
}
