
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PreviewPuzzle from '@/components/PreviewPuzzle.vue';

import { PuzzlePresetDb } from '../../../../functions/src/models/db/PuzzlePreset';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    PreviewPuzzle,
  },
})
export default class RendererCheck extends Vue {
  @PuzzlePresetStore.State('puzzlePresets') presets!: PuzzlePresetDb[];

  mounted() {
    this.$store.dispatch('puzzlePresetStore/bindPuzzlePresets');
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresets');
  }
}
