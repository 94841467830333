
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import firebase from 'firebase/compat';
import { PuzzlePresetDb } from 'functions/src/models/db/PuzzlePreset';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';
import NotFound from '@/components/NotFound.vue';
import PreviewPuzzle from '@/components/PreviewPuzzle.vue';

import { PageDb } from '../../../functions/src/models/db/Page';
import {
  ContentItemApproved,
  ContentItemStatus,
  PuzzlePresetContentItem,
} from '../../../functions/src/models/db/PuzzlePresetContent';
import { IGeneratedKoprol } from '../../../functions/src/models/puzzles/IGeneratedPuzzle';
import { UserMixin } from '../../mixins/User';
import { IState } from '../../models/models';
import { RouteName } from '../../router/RouteName';
import { auth, db, functions } from '../../store/db';
import { functionsUrl } from '../../store/firestore-config';
import { firebaseConfig } from '../../store/firestore-config';
import { showError } from '../../utils/errors';
import NoAccess from '../NoAccess.vue';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    PreviewPuzzle,
    BreadCrumbs,
    NotFound,
    NoAccess,
  },
})
export default class PuzzlesContentDetail extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresetDetail') puzzlePresetDetail!: PuzzlePresetDb;
  @Prop() id!: string;
  @Prop() contentId!: string;

  externalUse = false;
  useStateIsSet = false;
  token = '';
  loading = true;
  reserved: { id: string; data?: PageDb }[] = [];

  get isUnavailable() {
    return (
      this.puzzlePresetContentDetail?.status === 'used' ||
      this.puzzlePresetContentDetail?.status === 'reserved' ||
      this.puzzlePresetContentDetail?.approved === 'rejected'
    );
  }

  get userHasAccess() {
    if (this.loading) return true;

    return (
      !this.puzzlePresetDetail.titles ||
      this.currentUserTitles === 'ALL' ||
      this.puzzlePresetDetail.titles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get puzzlePresetContentDetail() {
    return (this.$store.state as IState).puzzlePresetContentDetail;
  }
  get debugdata() {
    return JSON.stringify(this.puzzlePresetContentDetail?.generated);
  }

  get downloadUrl() {
    return `${functionsUrl}previewPuzzle?presetId=${this.puzzlePresetDetail?.id}&puzzleId=${this.puzzlePresetContentDetail?.id}&token=${this.token}`;
  }

  get gcsUrl() {
    return `https://console.cloud.google.com/storage/browser/${firebaseConfig.projectId}.appspot.com/puzzle-assets/${this.puzzlePresetContentDetail?.id}`;
  }

  get breadCrumbs() {
    if (!this.puzzlePresetDetail || !this.puzzlePresetContentDetail) return [];
    return [
      {
        name: 'Alle puzzels',
        routeObject: { name: RouteName.AllPuzzles },
      },
      {
        name: this.puzzlePresetDetail.name,
        routeObject: {
          name: RouteName.PresetOverview,
          params: { renderer: this.puzzlePresetDetail.renderer, id: this.puzzlePresetDetail.id },
        },
      },
      {
        name:
          (this.puzzlePresetContentDetail.generated &&
            (this.puzzlePresetContentDetail.generated as IGeneratedKoprol).title) ||
          this.puzzlePresetContentDetail.id,
      },
    ];
  }

  getFormattedDate(date: string | undefined, fmt = 'd MMMM yyyy') {
    if (!date) return '';
    try {
      return format(new Date(date), fmt, { locale: nl });
    } catch (err) {
      console.error('Unknown date format', date, err.message);
      return date;
    }
  }

  getStatusType(status: ContentItemStatus) {
    switch (status) {
      case 'initial':
        return 'is-success is-light';
      case 'reserved':
        return 'is-warning is-light';
      case 'used':
        return 'is-danger is-light';
    }
  }

  getStatusName(status: ContentItemStatus) {
    switch (status) {
      case 'initial':
        return 'Beschikbaar';
      case 'reserved':
        return 'Gereserveerd';
      case 'used':
        return 'Gebruikt';
    }
  }

  getApprovalType(status: ContentItemApproved) {
    switch (status) {
      case 'initial':
        return 'is-light';
      case 'approved':
        return 'is-success is-light';
      case 'rejected':
        return 'is-danger is-light';
    }
  }

  getApprovalName(status: ContentItemApproved) {
    switch (status) {
      case 'initial':
        return 'Niet gecontroleerd';
      case 'approved':
        return 'Goedgekeurd';
      case 'rejected':
        return 'Afgekeurd';
    }
  }

  getMessageType() {
    if (!this.puzzlePresetContentDetail) return '';
    switch (this.puzzlePresetContentDetail.approved) {
      case 'initial':
        return 'is-info';
      case 'approved':
        return 'is-success';
      case 'rejected':
        return 'is-danger';
    }
  }

  getMessage() {
    if (!this.puzzlePresetContentDetail) return '';
    if (this.puzzlePresetContentDetail.approved === 'rejected') {
      return 'Deze puzzel is afgekeurd en kan nergens gebruikt worden.';
    }
    if (this.puzzlePresetContentDetail.status !== 'initial') {
      return 'Deze puzzel is al gebruikt en kan niet meer goed- of afgekeurd worden.';
    }
    if (this.puzzlePresetContentDetail.approved === 'initial') {
      return "Als je de puzzel goedkeurt kan deze gebruikt worden in pagina's. Als je de puzzel afkeurt zal deze nooit gebruikt worden.";
    }
    if (this.puzzlePresetContentDetail.approved === 'approved') {
      return 'Deze puzzel is goedgekeurd en kan gebruikt worden.';
    }
  }

  async approve() {
    await this.save({ approved: 'approved' }, 'Puzzel goedgekeurd');
  }

  async unreject() {
    await this.save({ approved: 'initial' }, 'Puzzel niet meer afgekeurd');
  }

  async reject() {
    await this.save({ approved: 'rejected', status: 'used' }, 'Puzzel afgekeurd');
  }

  async useManually() {
    const used = !this.puzzlePresetContentDetail?.manuallyUsedBy;
    await this.save(
      { manuallyUsedBy: used ? this.currentUserEmail : null },
      `Puzzel handmatig ${used ? 'gebruikt' : 'niet langer gebruikt'}`
    );
  }

  async save(data: Partial<PuzzlePresetContentItem>, successMessage: string) {
    try {
      await this.$store.dispatch('savePuzzlePresetContentDetail', {
        presetId: this.id,
        contentId: this.contentId,
        contentData: data,
      } as Partial<PuzzlePresetContentItem>);

      // don't await, it can just finish in the background
      functions.httpsCallable('calculateAllStockRest')({
        preset: { ...this.puzzlePresetDetail, id: this.id },
      });
    } catch (err: unknown) {
      return showError(err);
    }

    this.$buefy.snackbar.open({
      message: successMessage,
      type: 'is-success',
    });
  }

  async refreshPreview(puzzlePresetContentDetail: PuzzlePresetContentItem) {
    await db
      .collection('puzzlepresets')
      .doc(this.id)
      .collection('content')
      .doc(this.contentId)
      .update({ pdfCache: firebase.firestore.FieldValue.delete() });
    window.location.reload();
  }

  async deleteContentItem() {
    await db
      .collection('puzzlepresets')
      .doc(this.id)
      .collection('content')
      .doc(this.contentId)
      .delete();
    this.$router.push({
      name: RouteName.PresetOverview,
      params: { renderer: this.puzzlePresetDetail.renderer, id: this.id },
      query: { view: 'stock' },
    });
  }

  createItemsWithName(currentArray: string[]) {
    return Promise.all(
      currentArray.map(async (pageId) => {
        const doc = await db.collection('pages').doc(pageId).get();
        const data = !doc.exists
          ? undefined
          : ({ id: doc.id, ...doc.data() } as PageDb & { id: string });
        return { id: pageId, data };
      })
    );
  }

  async mounted() {
    if (auth.currentUser) {
      this.token = await auth.currentUser.getIdToken();
    }

    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetDetail', this.id);
    await this.$store.dispatch('bindPuzzlePresetContentDetail', {
      presetId: this.id,
      contentId: this.contentId,
    });
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('unbindPuzzlePresetContentDetail');
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetDetail');
  }

  @Watch('puzzlePresetContentDetail')
  async onChangeContentDetail(puzzlePresetContentDetail: PuzzlePresetContentItem & { id: string }) {
    if (!puzzlePresetContentDetail) {
      return;
    }

    this.reserved = await this.createItemsWithName(puzzlePresetContentDetail.reserved);
  }

  @Watch('contentId')
  async onChangeContentId(contentId: string) {
    this.useStateIsSet = false;
    await this.$store.dispatch('bindPuzzlePresetContentDetail', {
      presetId: this.id,
      contentId: contentId,
    });
  }
}
