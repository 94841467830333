
import { Component, Prop, Vue } from 'vue-property-decorator';

import { RouteName } from '../../../router/RouteName';
import { PuzzleItem } from './PuzzlesOverview.vue';

@Component
export default class UnusedPuzzles extends Vue {
  @Prop() unusedPuzzles!: PuzzleItem[];

  currentPage = 1;

  get routeName() {
    return RouteName;
  }
}
