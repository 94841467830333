
import { Component, PropSync } from 'vue-property-decorator';

import { IWordfindPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import { WordPools } from '../../../../../functions/src/models/puzzles/IPuzzleGeneratorParams';
import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class WordfindPresetEditor extends TypedVue {
  @PropSync('presetData') preset!: IWordfindPreset;

  wordPools = WordPools;
}
